export enum UserPinnedEntityType {
  Room = "ROOM",
  Outside = "OUTSIDE",
}

export interface UserPinnedEntity {
  userId: string;
  propertyId: string;
  entityId: string;
  entityType: UserPinnedEntityType;
}
