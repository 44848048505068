import { Box, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import React from "react";

interface IconButtonProps {
  children?: JSX.Element;
  onClick?: (event: React.MouseEvent) => void;
  title?: string;
  width?: string;
  tooltipLabel?: string;
  disabled?: boolean;
  zIndex?: number;
}

export const MenuIconButton = ({
  width = "40px",
  children,
  onClick,
  title,
  tooltipLabel,
  disabled,
  zIndex,
}: IconButtonProps) => {
  return (
    <>
      <Tooltip placement="bottom" hasArrow label={tooltipLabel} aria-label={tooltipLabel}>
        <Box
          color="#111828"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="40px"
          width={width}
          px="10px"
          borderColor="gray.100"
          borderWidth="1px"
          borderRadius="6px"
          as="button"
          zIndex={zIndex || "auto"}
          onClick={onClick}
          disabled={disabled}
          _hover={
            !disabled
              ? {
                  color: "#4045F8",
                  borderColor: "#4045F8",
                  boxShadow: "0px 4px 4px rgba(157, 157, 157, 0.25)",
                }
              : {}
          }
        >
          {children}
          {Boolean(title) && (
            <Text color="white" fontWeight={500} fontSize="16px">
              {title}
            </Text>
          )}
        </Box>
      </Tooltip>
    </>
  );
};
